/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Source Sans Pro';
  --ion-default-font: 'Source Sans Pro';

  --ion-color-primary: #0088CE;
  --ion-color-primary-rgb: 0,136,206;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0078b5;
  --ion-color-primary-tint: #1a94d3;

  /** success **/
  --ion-color-success: #34B233;
  --ion-color-success-rgb: 52,178,51;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #2e9d2d;
  --ion-color-success-tint: #48ba47;

  /** info **/
  --ion-color-info: #FED100;
  --ion-color-info-rgb: 254,209,0;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0,0,0;
  --ion-color-info-shade: #e0b800;
  --ion-color-info-tint: #fed61a;

  /** Error **/
  --ion-color-error: #FF0000;
  --ion-color-error-rgb: 255,0,0;
  --ion-color-error-contrast: #ffffff;
  --ion-color-error-contrast-rgb: 255,255,255;
  --ion-color-error-shade: #e00000;
  --ion-color-error-tint: #ff1a1a;
}

/** Custom color classes **/
.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-error {
  --ion-color-base: var(--ion-color-error);
  --ion-color-base-rgb: var(--ion-color-error-rgb);
  --ion-color-contrast: var(--ion-color-error-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error-contrast-rgb);
  --ion-color-shade: var(--ion-color-error-shade);
  --ion-color-tint: var(--ion-color-error-tint);
}
