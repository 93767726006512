@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Regular.woff2') format('woff2'),
      url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Light.woff2') format('woff2'),
      url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Black.woff2') format('woff2'),
      url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Medium.woff2') format('woff2'),
      url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Bold.woff2') format('woff2'),
      url('./fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* ------ */

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-Bold.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-Light.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/Source_Sans_Pro/SourceSansPro-Black.woff2') format('woff2'),
      url('./fonts/Source_Sans_Pro/SourceSansPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

