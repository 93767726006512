.BaseTextDisplay {
  margin: 0;
  max-width: 80ch; /* increase readability of long lines of text */
  line-height: var(--lineHeight);
  font-size: var(--fontSize);
}

.BaseTextDisplay + .BaseTextDisplay {
  margin-top: calc(var(--lineHeight) * var(--fontSize));
}

.BaseTextDisplay.-HeadingM {
  color: var(--colors-shade80);
  
  font-family: var(--fontFamilies-headingM);
  font-weight: var(--fontWeights-headingM);
  letter-spacing: var(--letterSpacings-headingM);
  --fontSize: var(--fontSizes-headingM);
  --lineHeight: var(--lineHeights-headingM);
}

.BaseTextDisplay.-HeadingS {
  color: var(--colors-shade80);

  font-family: var(--fontFamilies-headingS);
  font-weight: var(--fontWeights-headingS);
  letter-spacing: var(--letterSpacings-headingS);
  --fontSize: var(--fontSizes-headingS);
  --lineHeight: var(--lineHeights-headingS);
}

.BaseTextDisplay.-BodyM {
  color: var(--colors-shade80);

  font-family: var(--fontFamilies-bodyM);
  font-weight: var(--fontWeights-bodyM);
  letter-spacing: var(--letterSpacings-bodyM);
  --fontSize: var(--fontSizes-bodyM);
  --lineHeight: var(--lineHeights-bodyM);

  white-space: pre-line;
  hyphens: auto;
}

.BaseTextDisplay.-BodyS {
  color: var(--colors-shade80);

  font-family: var(--fontFamilies-bodyS);
  font-weight: var(--fontWeights-bodyS);
  letter-spacing: var(--letterSpacings-bodyS);
  --fontSize: var(--fontSizes-bodyS);
  --lineHeight: var(--lineHeights-bodyS);

  white-space: pre-line;
  hyphens: auto;
}
