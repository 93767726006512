.QuickKeyboardActions {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;

  border-top: var(--hairline);
  box-shadow: var(--shadows-medium);

  animation: QuickKeyboardActions-slide-in .2s ease-out;
}

.QuickKeyboardAction {
  border: 0;
  color: var(--colors-surface);
  background: var(--colors-info);
  min-height: var(--quickActionsBar-height);
  max-height: var(--quickActionsBar-height);
  flex: 1 0 50%;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  font-family: var(--fontFamilies-microbutton);
  font-size: var(--fontSizes-microbutton);
  font-weight: var(--fontWeights-microbutton);
  letter-spacing: var(--letterSpacings-microbutton);
  line-height: var(--lineHeights-microbutton);
  text-transform: uppercase;
}

.QuickKeyboardAction + .QuickKeyboardAction {
  border-left: var(--hairline);
}

@keyframes QuickKeyboardActions-slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
