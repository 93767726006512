.SignatureInput-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-top: 0;
  width: 100%;
  background: var(--colors-background);
}

.SignatureInput-content > .image {
  max-width: 240px;
  max-height: 240px;
}
