@keyframes appear {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

svg * {
  fill: currentColor;
}

/** Form styles **/
.form {
  --paddingV: 2rem;
  --paddingH: 1rem;

  padding: var(--paddingV) var(--paddingH);
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 800px;
  margin: auto;
  /*  */
  scroll-padding-bottom: var(--quickActionsBar-height); 
  scroll-padding-top: var(--accordionBar-height);
}

.form > * + *, .accordion-container > .content > * + * {
  margin-top: var(--spacing-vr1P5);
}

.form::before,
.form::after {
  content: "";
  border-left: var(--hairline);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 20;
}

.form::before {
  left: calc(var(--paddingH) - 1px);
}

.form::after {
  right: calc(var(--paddingH) - 1px);
}

/** Buttons style **/
ion-button {
  --border-radius: var(--radii-hard);
  margin-left: 0;
  margin-right: 0;
}
.form > ion-button {
  height: var(--spacing-vr2P5);
}

/** Inputs style **/
.item {
  background-color: transparent;
}
.item > .input-icon, .item > .input-error {
  align-self: center;
}
.item > .input-icon.-isPrefix {
  margin-right: 1rem;
  color: var(--colors-shade80)
}

.item, .accordion-container {
  animation: appear .4s ease-out;
}

.item > .input-error {
  color: var(--ion-color-error);
  padding: 0
}


/** Input border style **/
.border-input {
  border: var(--borderWidths-hairline) solid var(--colors-shade80);
  --min-height: 56px;
}

.border-input.-hasError:not(:focus-within) {
  border-color: var(--ion-color-error);
  color: var(--ion-color-error);
}

.border-input:focus-within {
  border-color: var(--ion-color-primary);
}

.border-input.-highlighted {
  --background: var(--colors-info);
  border-color: var(--colors-onInfo);
  color: var(--colors-onInfo);
}
/** Input wrapper styles **/
.input-wrapper {
  display: flex;
}

/* Increases specificity */
.input-label.input-label {
  --color: var(--colors-shade80);
  line-height: normal;
  white-space: normal;
}

/** Input icons styles **/
.input-icon.-isPrefix {
  color: var(--colors-shade80);
  margin-right: 1rem;
}

.input-icon.-isAction {
  color: var(--colors-info);
  margin-left: auto;
}

.input-icon.-isDanger {
  color: var(--colors-primary); /* primary color is Red for this design system */
  margin-left: auto;
}

/** Checkbox style **/
.checkbox-item::part(native) {
  padding-left: 0;
  background-color: transparent;
}
.checkbox-icon {
  margin-left: 0;
  margin-right: 16px;
  --size: 24px;
  --border-color: var(--colors-info);
  --border-radius: var(--radii-hard);
}

/** Label select style **/
.label-select {
  --background: transparent;
}
.label-select::part(native) {
  padding-left: 0;
}
.label-select > .label-container {
  margin: var(--spacing-vr0) 0;
  margin-left: -4px;
  margin-right: -4px;
}
.label-select > .label-container > .choice-chip {
  background-color: var(--colors-background);
}

.label-select > .label-container > .choice-chip:focus {
  box-shadow: 0 0 0 .125rem rgba(var(--ion-color-primary-rgb), 0.2);
}

.label-select > .label-container > .choice-chip.-selected {
  background-color: var(--colors-info);
  color: var(--colors-onPrimary);
}

/** Media Input style **/
.media-input > .item {
  margin: 0;
  --min-height: 56px;
}
.media-input > video, .media-input > audio {
  width: 100%;
}

/** Accordion style **/
.accordion-container {
  background-color: var(--colors-surface);
  border-top: var(--hairline);
  border-bottom: var(--hairline);
  margin-left: calc(-1 * var(--paddingH, 1rem));
  margin-right: calc(-1 * var(--paddingH, 1rem));
}

.accordion-container.-isOpen {
  margin-top: var(--spacing-vr1P5);
}

.accordion-container:not(.-isOpen) + .accordion-container:not(.-isOpen) {
  margin-top: var(--spacing-vr0);
}

.accordion-container > .header {
  margin: 0;
}

.accordion-container > .header.-sticky {
  position: sticky;
  top: 0;
  z-index: 50;
  background: var(--colors-surface);
  border-bottom: var(--hairline);
}

.accordion-container > .header::part(native) {
  background-color: transparent;
}

.accordion-container > .header > .title {
  color: var(--colors-shade80);
  text-transform: uppercase;
  line-height: var(--lineHeights-headingXxs);
  font-size: var(--fontSizes-headingXxs);
  letter-spacing: var(--letterSpacings-headingXxs);
  font-weight: var(--fontWeights-headingXxs);
  font-family: var(--fontFamilies-headingXxs);
}

.accordion-container > .content {
  padding: var(--spacing-vr1P5) var(--paddingH);
  padding-bottom: var(--paddingV);
  display: flex;
  flex-direction: column;
}


/** Error badge style **/
.error-badge {
  border-radius: 0;
  min-width: 1.25rem;
  min-height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
