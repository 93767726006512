.PictureDisplay {
  z-index: 30;
  margin-left: calc(-1 * var(--paddingH, 1rem));
  margin-right: calc(-1 * var(--paddingH, 1rem));
  line-height: 0;
  display: flex;
}

.PictureDisplay > .image {
  max-width: 100%;
  margin: auto;
}

.PictureDisplay > .placeholder {
  background-color: var(--colors-shade10);
  padding: 2rem;
  color: var(--colors-shade40);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
