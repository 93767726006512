/* https://ionicframework.com/docs/api/ripple-effect */
.ripple-parent {
  position: relative;
  overflow: hidden;
}

/* TranslateY was based on label height */
.label-stacked.sc-ion-label-md-h {
  transform: translateY(8px);
  font-size: 0.75rem;
}