/** Generated file, don't edit it ! **/

:root {
  /** Custom borderWidths variables **/
  --borderWidths-chunky: 8px;
  --borderWidths-fat: 4px;
  --borderWidths-regular: 2px;
  --borderWidths-hairline: 1px;

  /** Custom colors variables **/
  --colors-onInfo: rgba(255, 255, 255, 1);
  --colors-onSuccess: rgba(255, 255, 255, 1);
  --colors-onError: rgba(0, 0, 0, 1);
  --colors-onShade: rgba(255, 255, 255, 1);
  --colors-onPrimary: rgba(255, 255, 255, 1);
  --colors-onSurface: rgba(0, 0, 0, 1);
  --colors-onBackground: rgba(0, 0, 0, 1);
  --colors-shade40: rgba(154, 154, 160, 1);
  --colors-shade10: rgba(2, 2, 20, 0.07);
  --colors-shade20: rgba(221, 221, 225, 1);
  --colors-background: rgba(255, 255, 255, 1);
  --colors-surface: rgba(248, 248, 248, 1);
  --colors-shade80: rgba(64, 64, 68, 1);
  --colors-shadeMain: rgba(109, 111, 114, 1);
  --colors-primary: rgba(255, 0, 0, 1);
  --colors-shade100: rgba(0, 0, 0, 1);
  --colors-info: rgba(0, 136, 206, 1);
  --colors-success: rgba(52, 178, 51, 1);
  --colors-error: rgba(254, 209, 0, 1);

  /** Custom fontFamilies variables **/
  --fontFamilies-code: "Roboto Mono";
  --fontFamilies-label: "Source Sans Pro";
  --fontFamilies-button: "Source Sans Pro";
  --fontFamilies-microbutton: "Source Sans Pro";
  --fontFamilies-bodyXsBold: "Source Sans Pro";
  --fontFamilies-bodyXs: "Source Sans Pro";
  --fontFamilies-bodySBold: "Frank Ruhl Libre";
  --fontFamilies-bodyS: "Frank Ruhl Libre";
  --fontFamilies-bodyMBold: "Source Sans Pro";
  --fontFamilies-bodyM: "Source Sans Pro";
  --fontFamilies-bodyL: "Source Sans Pro";
  --fontFamilies-headingXxs: "Source Sans Pro";
  --fontFamilies-headingXs: "Frank Ruhl Libre";
  --fontFamilies-headingS: "Frank Ruhl Libre";
  --fontFamilies-headingM: "Source Sans Pro";
  --fontFamilies-headingL: "Source Sans Pro";

  /** Custom fontSizes variables **/
  --fontSizes-code: 0.75rem;
  --fontSizes-label: 0.9375rem;
  --fontSizes-button: 1rem;
  --fontSizes-microbutton: 0.6875rem;
  --fontSizes-bodyXsBold: 0.75rem;
  --fontSizes-bodyXs: 0.75rem;
  --fontSizes-bodySBold: 0.8125rem;
  --fontSizes-bodyS: 0.875rem;
  --fontSizes-bodyMBold: 1rem;
  --fontSizes-bodyM: 1rem;
  --fontSizes-bodyL: 1.25rem;
  --fontSizes-headingXxs: 0.75rem;
  --fontSizes-headingXs: 0.9375rem;
  --fontSizes-headingS: 1.25rem;
  --fontSizes-headingM: 1.5rem;
  --fontSizes-headingL: 2.5rem;

  /** Custom fontWeights variables **/
  --fontWeights-code: 500;
  --fontWeights-label: 400;
  --fontWeights-button: 700;
  --fontWeights-microbutton: 700;
  --fontWeights-bodyXsBold: 700;
  --fontWeights-bodyXs: 400;
  --fontWeights-bodySBold: 900;
  --fontWeights-bodyS: 400;
  --fontWeights-bodyMBold: 700;
  --fontWeights-bodyM: 400;
  --fontWeights-bodyL: 400;
  --fontWeights-headingXxs: 700;
  --fontWeights-headingXs: 900;
  --fontWeights-headingS: 900;
  --fontWeights-headingM: 700;
  --fontWeights-headingL: 700;

  /** Custom letterSpacings variables **/
  --letterSpacings-code: 0em;
  --letterSpacings-label: 0em;
  --letterSpacings-button: 0em;
  --letterSpacings-microbutton: 0.05em;
  --letterSpacings-bodyXsBold: 0.03em;
  --letterSpacings-bodyXs: 0em;
  --letterSpacings-bodySBold: 0em;
  --letterSpacings-bodyS: 0em;
  --letterSpacings-bodyMBold: 0em;
  --letterSpacings-bodyM: 0em;
  --letterSpacings-bodyL: 0em;
  --letterSpacings-headingXxs: 0.07em;
  --letterSpacings-headingXs: 0em;
  --letterSpacings-headingS: 0em;
  --letterSpacings-headingM: 0em;
  --letterSpacings-headingL: 0em;

  /** Custom lineHeights variables **/
  --lineHeights-code: 1.33;
  --lineHeights-label: 1.60;
  --lineHeights-button: 1.00;
  --lineHeights-microbutton: 1.45;
  --lineHeights-bodyXsBold: 1.33;
  --lineHeights-bodyXs: 1.00;
  --lineHeights-bodySBold: 1.23;
  --lineHeights-bodyS: 1.14;
  --lineHeights-bodyMBold: 1.50;
  --lineHeights-bodyM: 1.50;
  --lineHeights-bodyL: 1.60;
  --lineHeights-headingXxs: 1.33;
  --lineHeights-headingXs: 1.07;
  --lineHeights-headingS: 1.20;
  --lineHeights-headingM: 1.00;
  --lineHeights-headingL: 1.00;

  /** Custom radii variables **/
  --radii-hard: 2px;

  /** Custom shadows variables **/
  --shadows-medium: 0px 0px 4px rgba(0, 0, 0, 0.1);

  /** Custom spacing variables **/
  --spacing-vr3: 4rem;
  --spacing-vr3P5: 6rem;
  --spacing-vr2P5: 3rem;
  --spacing-vr2: 2rem;
  --spacing-vr1P5: 1.5rem;
  --spacing-vr1: 1rem;
  --spacing-vr0: 0.5rem;
  --spacing-vrM1: 0.25rem;
  --spacing-vrM2: 0.125rem;
}